<template>
  <div class="login">
    <v-row no-gutters>
      <v-col cols="12" md="7" class="login-bg mobile-hidden"></v-col>
      <v-col cols="12" md="5">
        <div class="text-center">
          <a
            href="https://www.soocel.com"
            class="d-flex justify-center align-center"
          >
            <img
              src="@/assets/images/soocel-logo.png"
              height="45"
              class="logo"
            />
          </a>
        </div>

        <h2 class="text-center">Influencer Login</h2>

        <v-form class="login-form">

          <v-radio-group
            v-model="loginType"
            row
          >
            <v-radio
              color="primary"
              label="Login with password"
              value="password"
            ></v-radio>
            <v-radio
              color="primary"
              label="Login with OTP"
              value="otp"
            ></v-radio>
          </v-radio-group>

          <Password v-if="loginType === 'password'" />
          <Otp v-else />

          <p class="d-flex justify-space-between mt-4">
            <router-link to="/user/forgot-password">Forgot Password</router-link>
            <router-link to="/user/register">Register</router-link>
          </p>

          <p class="text-center mt-8">
            By registering, you are agreeing to the <br>
            <router-link to="/legal/privacy-policy">Privacy Policy</router-link> and <router-link to="/legal/terms">Terms &amp; Conditions</router-link>.
          </p>

        </v-form>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import Password from './components/password.vue'
import Otp from './components/otp.vue'

export default {
  components: { Otp, Password },
  data () {
    return {
      loginType: 'password'
    }
  }
}
</script>

<style scoped>
</style>
